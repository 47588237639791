import { IS_OPENED_FROM_DOCUMENTS_LIST } from "../constants/GeneralConstants";
import { axiosInstance } from "./RemoteService";

const PendingDocumentService = {};

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001/api';

const baseApiUrl = backendUrl;
/**
 * 
 * @param {*} emailId 
 * @returns pending documents list
 */
PendingDocumentService.getPendingDocumentsList = (emailId) => {

  return axiosInstance.get(`${baseApiUrl}/emailsignatures/${emailId}/nextdocuments`);
}

/**
 * 
 * @param {*} IsOpenedFromDocumentList 
 */
PendingDocumentService.setIsOpenedFromDocumentList = (IsOpenedFromDocumentList) => {
  localStorage.setItem(IS_OPENED_FROM_DOCUMENTS_LIST, IsOpenedFromDocumentList);
}

/**
 * 
 * @returns if is opened from document list
 */
PendingDocumentService.getIsOpenedFromDocumentList = () => {
  return localStorage.getItem(IS_OPENED_FROM_DOCUMENTS_LIST);
}

/**
 * 
 */
// PendingDocumentService.checkIsOpenedFromDocumentList = () => {
//   if (this.getIsOpenedFromDocumentList() === 'true') {
//     if (!this.vidSignerApiService.isBatchSignature) {
//       this.vidSignerApiService.otp = "";
//       this.vidSignerApiService.otpSended = false;
//     }
//     return;
//   }

//   this.setIsOpenedFromDocumentList(false);
// }

export default PendingDocumentService;