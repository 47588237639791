import arrowRight from './../../assets/icons/arrow-right.svg';
import documentLoadingGif from './../../assets/images/logo_vidsigner_green.gif';
import whiteLoaderGif from '../../assets/images/white_loader.gif';
import './BatchDocumentItem.css';
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import DocumentSnapshotService from "../../services/DocumentSnapshotService";
import { useNavigate } from "react-router-dom";
import { ArrowIcon } from "../SvgComponents/ArrowIcon/ArrowIcon";
import { BatchContext } from "../../contexts/BatchContext";
import { IS_CUSTOM } from "../../constants/GeneralConstants";
const { Form } = require("react-bootstrap");


export const BatchDocumentItem = ({ document, emailId, isBatchMultisignature }) => {
    const [snapshotImage, setSnapshotImage] = useState('');
    const [isCustom, setIsCustom] = useState(false);

    const { t } = useTranslation();

    const navigate = useNavigate();

    const { DocGUI, FileName, UploadTime } = document;

    const { selectBatchDocument, unselectBatchDocument, isDocumentSelected } = useContext(BatchContext);

    // On Init
    useEffect(() => {
        const isCustom = localStorage.getItem(IS_CUSTOM) === "true";
        setIsCustom(isCustom);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSnapshot = () => {
        if (!isBatchMultisignature) {
            DocumentSnapshotService.getDocumentSnapshot(DocGUI)
                .then((response) => {
                    const snapshot = response.data.data.DocSnapshot;
                    setSnapshotImage(snapshot);
                });
        }
    };

    const viewDocument = () => {
        let link = '/document';

        if (document.SignerDTO.Form && document.SignerDTO.Form.FormDisplay.toLowerCase() === "beforepreview") link =  "/questions";
        if (document.SignerDTO.Form && document.SignerDTO.Form.FormDisplay.toLowerCase() === "onpreview") link =  "/document-questions";

        navigate(link, {
            state: {
                documents: document,
                emailId,
            }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getSnapshot, []);

    const handleSelectDocument = (e) => {
        const { checked } = e.target;

        if (checked) {
            selectBatchDocument(DocGUI);
            return;
        }

        unselectBatchDocument(DocGUI);
    }

    return (
        <div className={`batch-document-container ${isDocumentSelected(DocGUI) ? 'selected-batch-item' : ''}`}>
            {
                isBatchMultisignature && 
                    <Form>
                        <Form.Check type="checkbox">
                        <Form.Check.Input type="checkbox" checked={isDocumentSelected(DocGUI)} onChange={handleSelectDocument} />
                            <Form.Check.Label></Form.Check.Label>
                        </Form.Check>
                    </Form>
            }
            {
                !isBatchMultisignature ? 
                    snapshotImage ? <img src={`data:image/png;base64,${snapshotImage}`} className="preview-documemt-batch" alt="Snapshot" /> : 
                    <img src={isCustom ? whiteLoaderGif : documentLoadingGif} alt="LoadingGif" /> : null
            }
           
            <div onClick={viewDocument} className='document-info-container'>
                <h5>{FileName}</h5> 
                <p>{t('documents.send_date')} {UploadTime.split(' ')[0]}</p> 
            </div>
            <button disabled={false} type="button" className="btn btn-primary btn-active button-only" onClick={viewDocument}>
                <span className="cancel-text">{t('general.see')}</span>
                <ArrowIcon />
            </button>
            <img onClick={viewDocument} className='batch-document-item-arrow-right' src={arrowRight} alt="Arrow right" />
        </div>
    );
}