import { useEffect, useState } from "react";
import "./QRLoader.css";
import EmailSignatureService from "../../services/EmailSignatureService";
import { Loader } from "../Loader/Loader";
import { Container } from 'react-bootstrap';
import { useTranslation } from "react-i18next";


export const QRLoader = ({ signerGuid, setReceivedGraphData }) => {
  const { t } = useTranslation();
  const [loadingQRImage, setLoadingQR] = useState(false);
  const [qrData, setQRData] = useState({});
  const { qr64Data, qrToken } = qrData;

  //On Init
  useEffect(() => {
    getQRData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (qrToken) pollingQRGraphData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrData]);

  const getQRData = () => {
    setLoadingQR(true);
    const body = {
      "SignersGuid": [
        signerGuid
      ]
    };

    EmailSignatureService.getQRData(body)
      .then((response) => {
        const { QRCodeImage, Token } = response.data.data;
        setQRData({
          qr64Data: QRCodeImage,
          qrToken: Token
        });
        setLoadingQR(false);
      });
  }

  const pollingQRGraphData = async () => {
    if (sessionStorage.getItem("SIGNED_DOC")) return;
    try {
      const response = await EmailSignatureService.pollingQR(qrToken);

      const { SignatureImage, SignatureRawData } = response.data.data;

      setReceivedGraphData({
        SignatureImage,
        SignatureRawData,
      });

    } catch (error) {
    }

    pollingQRGraphData();
  }

  return (
    <>
      {
        loadingQRImage ? <Loader /> : <Container className="qr-container">
          <img src={`data:image/png;base64,${qr64Data}`} className="qr-image" alt="QR Code" />
          <p className="qr-instructions-title">{t('sign.better_experience')}</p>
          <p>{t('sign.you_can_scan')}</p>
        </Container>
      }
    </>
  );
};
