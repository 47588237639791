import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./SignerData.css";
import { useForm } from "react-hook-form";
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import SignerDataService from "../../services/SignerDataService";
import { useNavigate } from "react-router-dom";
import { ArrowIcon } from "../../components/SvgComponents/ArrowIcon/ArrowIcon";
import { left } from "inquirer/lib/utils/readline";

export const SignerData = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { Form } = require("react-bootstrap");
  const { register, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
  const [showEmptyOption, setShowEmptyOption] = useState(false);
  const [loading, setLoading] = useState(false);

  const signerGUI = location.state.documents.SignerDTO.SignerGUI;
  const signerData = location.state.documents.SignerDTO.SignerData;
  const signerName = location.state.documents.SignerDTO.SignerName;
  const numberID = location.state.documents.SignerDTO.NumberID;
  const typeOfID = location.state.documents.SignerDTO.TypeOfID;
  const signerNameProperties = signerData.SignerNameProperties;
  const typeOfIdProperties = signerData.TypeOfIdProperties;
  const numberIdProperties = signerData.NumberIdProperties;
  const typeOfIdsAllowed = signerData.TypeOfIdsAllowed;
  const form = location.state.documents.SignerDTO.Form;

  const sendRequestPersonalData = (data) => {
    setLoading(true);
    const body = {
      SignerName: data.name,
      TypeOfID: data.typeLegalId,
      NumberId: data.legalId
    };

    SignerDataService.sendSignerData(signerGUI, body)
      .then(response => {
        let link = "/document";
        if (form && (formDisplayIsNullOrEmpty(form.FormDisplay) || form.FormDisplay.toLowerCase() === "beforepreview")) link = "/questions";
        if (form && form.FormDisplay.toLowerCase() === "onpreview") link = "/document-questions";

        location.state.documents.SignerDTO.SignerName = body.SignerName;
        location.state.documents.SignerDTO.NumberID = body.NumberId;
        location.state.documents.SignerDTO.TypeOfID = body.TypeOfID;
        navigate(link, {
          state: {
            documents: location.state.documents,
            emailId: location.state.emailId,
          }
        });
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  }

  useEffect(() => {
    if (typeOfIdProperties.Required.toUpperCase() === "OPTIONAL") {
      setShowEmptyOption(true);
    }
    if (numberID) {
      setValue("legalId", numberID);
    }
    if (signerName) {
      setValue("name", signerName);
    }
  }, [typeOfIdProperties, numberID, signerName, numberIdProperties, signerNameProperties, setValue]);

  const isNotRequired = (propierty) => {
    return propierty.Required.toUpperCase() === "NO";
  }

  const signerNameisRequired = () => {
    return signerNameProperties.Required.toUpperCase() === "MANDATORY";
  }

  const typeOfIdisRequired = () => {
    return typeOfIdProperties.Required.toUpperCase() === "MANDATORY";
  }

  const numberIdisRequired = () => {
    return numberIdProperties.Required.toUpperCase() === "MANDATORY";
  }

  const formDisplayIsNullOrEmpty = (formDisplay) => {
    return (formDisplay === null || formDisplay === '');
  }

  return (
    <Container className="container-row row-box form-view">
      <Row>
        <div className="welcome-container">
          <div className="welcome-text-container">
            <h3 style={{ fontWeight: 800 }}>{t('signer_data.title')}</h3>
            <strong><p>{t('signer_data.description')}</p></strong>
          </div>
        </div>
        <hr className="welcome-separator" />
      </Row>
      <Row>
        <Form onSubmit={handleSubmit(sendRequestPersonalData)} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          {!isNotRequired(signerNameProperties) &&
            <div className="textbox-container">
              <label>{t('signer_data.name')} {signerNameisRequired() && <span>*</span>}</label>
              <Form.Control
              style={{textAlign: 'left'}}
                type="text"
                {...register('name', { required: signerNameisRequired() })}
                disabled={!signerNameProperties.Editable}
              />
              {errors.name?.type === 'required' && <p className="required-field"> {t('signer_data.required_field')} </p>}
            </div>
          }
          {!isNotRequired(typeOfIdProperties) &&
            <div className="textbox-container">
              <label>{t('signer_data.document_type')} {typeOfIdisRequired() && <span>*</span>}</label>
              <Form.Select
                {...register('typeLegalId', {
                  validate: (value) => {
                    if (typeOfIdisRequired() || getValues('legalId')) {
                      return value !== "" || t('signer_data.required_field');
                    }
                    return true;
                  }
                })}
                defaultValue=""
                disabled={!typeOfIdProperties.Editable}
              >
                <option disabled={!showEmptyOption} value="">
                  {t('signer_data.select')}
                </option>
                {typeOfIdsAllowed.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Select>
              {errors.typeLegalId && (
                <p className="required-field">{t('signer_data.required_field')}</p>
              )}
            </div>
          }
          {!isNotRequired(numberIdProperties) &&
            <div className="textbox-container">
              <label>{t('signer_data.number_id')} {numberIdisRequired() && <span>*</span>}</label>
              <Form.Control
              style={{textAlign: 'left'}}
                type="text"
                {...register('legalId', {
                  validate: (value) => {
                    if (numberIdisRequired() || getValues('typeLegalId')) {
                      return value !== "" || t('signer_data.required_field');
                    }
                    return true;
                  }
                })}
                disabled={!numberIdProperties.Editable}
              />
              {errors.legalId && (
                <p className="required-field">{t('signer_data.required_field')}</p>
              )}
            </div>
          }
          <Row className="action-buttons mobile-button">
            <Col md={2} xs={1} lg={2}></Col>
            <Col md={8} xs={10} lg={8}>
              <button type="submit" className="btn btn-primary btn-active" disabled={loading}>
                <span className="cancel-text">{t('general.next')}</span>
                <ArrowIcon />
              </button>
            </Col>
            <Col md={2} xs={1} lg={2}></Col>
          </Row>
        </Form>
      </Row>
    </Container >
  );
};
