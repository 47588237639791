import { axiosInstance } from "./RemoteService";

const SignatureDelegationService = {};

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001/api';

const baseApiUrl = backendUrl;

SignatureDelegationService.signatureDelegation = (signerGuid, body) => {

  return axiosInstance.post(`${baseApiUrl}/signaturedelegation/${signerGuid}`, body);
}

export default SignatureDelegationService;