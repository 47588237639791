import { axiosInstance } from "./RemoteService";

const EmailSignatureService = {};

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001/api';

const baseApiUrl = backendUrl;

EmailSignatureService.sendOTPSms = (emailId, body) => {

  return axiosInstance.put(`${baseApiUrl}/emailsignatures/${emailId}/otpsms`, body);
}

EmailSignatureService.sendOTPCode = (emailId, body) => {

  return axiosInstance.put(`${baseApiUrl}/emailsignatures/${emailId}/otpcode`, body);
}

EmailSignatureService.resendOtpSms = (emailId, body) => {

  return axiosInstance.put(`${baseApiUrl}/emailsignatures/${emailId}/resendotpsms`, body);
}

EmailSignatureService.otpCall = (emailId, body) => {

  return axiosInstance.put(`${baseApiUrl}/emailsignatures/${emailId}/otpcall`, body);
}

EmailSignatureService.sendEvidence = (emailId, body) => {

  return axiosInstance.post(`${baseApiUrl}/emailsignatures/${emailId}/evidence`, body);
}

EmailSignatureService.getQRData = (body) => {
  return axiosInstance.post(`${baseApiUrl}/emailsignatures/qr`, body);
}

EmailSignatureService.pollingQR = (tokenId) => {
  return axiosInstance.get(`${baseApiUrl}/emailsignatures/qr/${tokenId}/polling`);
}

EmailSignatureService.sendQRGraphData = (tokenId, body) => {
  return axiosInstance.put(`${baseApiUrl}/emailsignatures/qr/${tokenId}`, body);
}

EmailSignatureService.sendSign = (signerGUI, body) => {

  return axiosInstance.post(`${baseApiUrl}/emailsignatures/${signerGUI}`, body);
}

EmailSignatureService.signMultipleDocuments = (body) => {
  return axiosInstance.post(`${baseApiUrl}/emailsignatures`, body);
}

EmailSignatureService.getSSIUrl = (signerGuid) => {
  let targetName = 'REMOTE_AUTH_VIDWALLET_LOCAL';
  if (process.env.REACT_APP_ENV === 'development') targetName = 'REMOTE_AUTH_VIDWALLET_DEV';
  if (process.env.REACT_APP_ENV === 'preproduction') targetName = 'REMOTE_AUTH_VIDWALLET_PRE';
  if (process.env.REACT_APP_ENV === 'production') targetName = 'REMOTE_AUTH_VIDWALLET_PRO';

  return axiosInstance.get(`${baseApiUrl}/emailsignatures/auth/url/${targetName}?id=${signerGuid}`);
}

EmailSignatureService.sendSSICode = (targetName, body) => {
  return axiosInstance.post(`${baseApiUrl}/emailsignatures/auth/code/${targetName}`, body);
}

EmailSignatureService.getAppearanceConfig = (emailId) => {
  return axiosInstance.get(`${baseApiUrl}/emailsignatures/${emailId}/appearance`);
}

EmailSignatureService.getCriiptoUrl = (signerGuid) => {
  return axiosInstance.get(`${baseApiUrl}/emailsignatures/${signerGuid}/criiptourl`);
}

EmailSignatureService.sendCriiptoCode = (emailId, body) => {
  return axiosInstance.post(`${baseApiUrl}/emailsignatures/${emailId}/criiptocode`, body);
}

EmailSignatureService.getAOCValidUrl = (signerGuid) => {
  return axiosInstance.get(`${baseApiUrl}/emailsignatures/${signerGuid}/aocvalidurl`);
}

EmailSignatureService.sendAOCValidCode = (emailId, body) => {
  return axiosInstance.post(`${baseApiUrl}/emailsignatures/${emailId}/aocvalidcode`, body);
}

EmailSignatureService.sendSwisscomSignRequest = (signerGuid, body) => {
  return axiosInstance.post(`${baseApiUrl}/emailsignatures/swisscom/${signerGuid}/signrequest`, body);
}

EmailSignatureService.completeSwisscomSignature = (signerGuid, requestID, body) => {
  return axiosInstance.post(`${baseApiUrl}/emailsignatures/swisscom/${signerGuid}/completesignature/${requestID}`, body);
}

export default EmailSignatureService;