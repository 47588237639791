import { axiosInstance } from "./RemoteService";

const OneShotService = {};

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001/api';

const baseApiUrl = backendUrl;

OneShotService.createRequest = (signerGuid) => {

  return axiosInstance.post(`${baseApiUrl}/emailsignatures/${signerGuid}/oneshot`, '');
}

OneShotService.sendOTP = (signerGuid, requestId) => {

  return axiosInstance.put(`${baseApiUrl}/emailsignatures/${signerGuid}/oneshot/${requestId}/otp`, '');
}

OneShotService.getContract = (signerGuid, requestId) => {

  return axiosInstance.get(`${baseApiUrl}/emailsignatures/${signerGuid}/oneshot/${requestId}/contract`);
}

OneShotService.sign = (signerGuid, requestId, body) => {

  return axiosInstance.post(`${baseApiUrl}/emailsignatures/${signerGuid}/oneshot/${requestId}/sign`, body);
}


export default OneShotService;