import { publicIpv4 } from 'public-ip';
const ipLocation = require("iplocation");
const { detect } = require("detect-browser");

export const getEvidenceData = async () => {
  try {
    const clientIp = await publicIpv4();
    const ipData = await ipLocation(clientIp);
    const browser = detect();

    const evidenceLocation = ipData.latitude + ',' + ipData.longitude;
    const browserStringified = JSON.stringify(browser);
    const evidenceInfo = {
      IpAddress: clientIp,
      Location: evidenceLocation,
      From: browserStringified
    };

    return evidenceInfo;
  } catch (e) {
      const evidenceInfo = {
        IpAddress: '',
        Location: '',
        From: ''
      };

      return evidenceInfo;
    }
  };