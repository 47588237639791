import React, { useEffect, useState } from 'react';

export const ArrowIcon = ({ secondayButton, disabled }) => {

  const [color, setColor] = useState('');
  const DOMstyle = document.documentElement.style;

  // On Init
  useEffect(() => {
    var color = DOMstyle.getPropertyValue("--custom-primary-text-color");
    if (secondayButton) color = DOMstyle.getPropertyValue("--custom-primary-background-color");
    if (disabled) color = '#9C9C9C';

    setColor(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <>
      <svg width="14" height="14" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 11 11">
        <g>
          <polygon fill={color} points="1.7,10.7 0.3,9.3 7.6,2 1,2 1,0 11,0 11,10 9,10 9,3.4 	"/>
        </g>
      </svg>
    </>
  );
}