import { axiosInstance } from "./RemoteService";

const RgpdTextService = {};

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001/api';

const baseApiUrl = backendUrl;

RgpdTextService.rgpdText = (signerGuid, language) => {
  return axiosInstance.get(`${baseApiUrl}/pendingsignatures/${signerGuid}/rgpdtext/${language}`);
}

RgpdTextService.rgpdAgreeText = (signerGuid, language) => {
  return axiosInstance.get(`${baseApiUrl}/pendingsignatures/${signerGuid}/rgpdagreetext/${language}`);
}

export default RgpdTextService;