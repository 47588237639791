import { axiosInstance } from "./RemoteService";

const DocumentSnapshotService = {};

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001/api';

const baseApiUrl = backendUrl;

DocumentSnapshotService.getDocumentSnapshot = (doucmentGuid) => {
  return axiosInstance.get(`${baseApiUrl}/documentsnapshot/${doucmentGuid}`);
}

export default DocumentSnapshotService;