import { axiosInstance } from "./RemoteService";

const OauthApiService = {};

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001/api';

const baseApiUrl = backendUrl + '/oauth';

OauthApiService.getToken = (emailId) => {
  const body = { emailId };
  return axiosInstance.post(`${baseApiUrl}/token`, body);
}

export default OauthApiService;