import { axiosInstance } from "./RemoteService";

const DisclaimerService = {};

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001/api';

const baseApiUrl = backendUrl;

DisclaimerService.getDisclaimer = (signerGuid, type) => {
  const lang = localStorage.getItem("i18nextLng");
  return axiosInstance.get(`${baseApiUrl}/pendingsignatures/${signerGuid}/disclaimertext/${type}/${lang}`);
}

export default DisclaimerService;