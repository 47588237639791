import { Modal } from "antd";
import LocalSignatureService from "../../services/LocalSignatureService";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AutofirmaOptional } from "./AutofirmaOptional";
import { AutofirmaMandatory } from "./AutofirmaMandatory";
import PendingDocumentService from "../../services/PendingDocumentService";
import { CheckIcon } from "../SvgComponents/CheckIcon/CheckIcon";
import { BatchContext } from "../../contexts/BatchContext";
import { ShowErrorModal } from "../Modals/ShowErrorModal/ShowErrorModal";

export const Autofirma = ({ signerDTO, clientIp, ipData, browser, emailId, docGuid, email, startCorrectSignMethod, setAutofirma }) => {
    const isAutofirma = signerDTO.LocalSignature;
    const signerGuid = signerDTO.SignerGUI;

    const [showAutofirmaModal, setShowAutofirmaModal] = useState(false);
    const [loadingAutofirma, setLoadingAutofirma] = useState(false);
    const [showModalErrorAutofirma, setShowModalErrorAutofirma] = useState(false);
    const [showModalSigningAutofirma, setShowModalSigningAutofirma] = useState(false);
    const [error, setError] = useState(false);

    const { remainingBatchDocuments, removeDocumentsFromBatch, isBatchMultisignature, selectedBatchDocumentGuids } = useContext(BatchContext);

    const navigate = useNavigate();
    const { t } = useTranslation();

    const checkAutofirma = () => {
        if (isAutofirma === 'optional') {
          setShowAutofirmaModal(true);
          return;
        }
    
        if (isAutofirma === 'mandatory') {
          setShowAutofirmaModal(true);
          signDocAutofirma();
        }
      };
    
      const signDocAutofirma = () => {
        loadAppAFirma().then(() => {
          doSelectCert();
        });
      };
    
      const loadAppAFirma = () => {
        setLoadingAutofirma(true);
        return new Promise((resolve, reject) => {
          window.AutoScript.cargarAppAfirma();
          window.AutoScript.setStickySignatory(true);
          setTimeout(() => { resolve(); }, 1500);
        });
      };
    
      const doSelectCert = () => {
        try {
          window.AutoScript.selectCertificate(
            "",
            showCertCallback.bind(),
            showErrorCallback.bind()
          );
    
        } catch (e) {
          console.log("Type: " + window.AutoScript.getErrorType() + "\nMessage: " + window.AutoScript.getErrorMessage());
          showErrorCallback();
        }
      };
    
      const showCertCallback = (certificateB64) => {
        var pki = window.forge.pki;
        var certPEM = "-----BEGIN CERTIFICATE-----\n" + certificateB64 + "\n-----END CERTIFICATE-----";
        var cert = pki.certificateFromPem(certPEM);
        var CN = cert.subject.getField('CN').value;
    
        var data = {
          signerGUI: signerGuid,
          certType: signerDTO.TypeOfID,
          certsData: certificateB64,
          hashAlgorithm: "SHA512",
          cn: CN
        };
    
        setLoadingAutofirma(false);
        setShowModalSigningAutofirma(true);
        setShowAutofirmaModal(false);
        doSign(data);
      }
    
      const showErrorCallback = (errorType, errorMessage) => {
        setShowAutofirmaModal(false);
        setLoadingAutofirma(false);
        setShowModalSigningAutofirma(false);
        setShowModalErrorAutofirma(true);
      };
    
      const doSign = (data) => {
        const requestDTO = {
          CertType: data.certType,
          CertsData: [data.certsData],
          HashAlgorithm: data.hashAlgorithm
        };
    
        LocalSignatureService.sendCertificateCalculatePreSignature(signerGuid, requestDTO)
          .then(result => {
            LocalSignatureService.getSignatureHash(signerGuid)
              .then((response) => {
                try {
                  window.AutoScript.sign(
                    response.data.data.PreSignatureContentDNIe,
                    "SHA512withRSA",
                    "NONE",
                    null,
                    showSignResultCallback.bind(),
                    showErrorCallback.bind());
                } catch (e) {
                  setShowModalSigningAutofirma(false);
                  try {
                    console.log("Type: " + window.AutoScript.getErrorType() + "\nMessage: " + window.AutoScript.getErrorMessage());
                  } catch (ex) {
                    console.log("Error: " + e);
                  }
                }
              })
              .catch(error => {
                console.error('Error: ' + error.error);
                setShowModalSigningAutofirma(false);
                setError(error.response.data.error);
              });
          })
          .catch(error => {
            console.error('Error:', error.error);
            setShowModalSigningAutofirma(false);
            setError(error.response.data.error);
          });
      }
    
      const showSignResultCallback = (signatureB64) => {
        const evidenceLocation = ipData.latitude + ',' + ipData.longitude;
        const browserStringified = JSON.stringify(browser);
    
        const dataJson = {
          Signature: signatureB64,
          HashAlgorithm: "SHA512",
          IpAddress: clientIp,
          Location: evidenceLocation,
          From: browserStringified
        };
    
        LocalSignatureService.sendSignatureResult(signerGuid, dataJson)
          .then(res => {
            removeDocumentsFromBatch(isBatchMultisignature ? selectedBatchDocumentGuids : [docGuid]);
            const pendingBatchDocsLength = remainingBatchDocuments - (selectedBatchDocumentGuids.length ? selectedBatchDocumentGuids.length : 1);

            if (pendingBatchDocsLength > 0) {
              removeDocumentsFromBatch([docGuid]);
                navigate('/batch-success', { state: {
                    emailId,
                }})
                return;
            }
            PendingDocumentService.getPendingDocumentsList(emailId)
              .then(res => {
                const pendingDocuments = res.data.data;
                setShowModalSigningAutofirma(false);
                setShowAutofirmaModal(false);
                navigate("/signature-success", {
                  state: {
                    pendingDocuments,
                    emailId,
                    email
                  }
                });
              })
              .catch(err => {
                setShowModalSigningAutofirma(false);
                setShowAutofirmaModal(false);
                navigate("/signature-success", {
                  state: {
                    pendingDocuments: [],
                    emailId,
                    email
                  }
                });
              });
          })
          .catch(function (error) {
            setShowModalSigningAutofirma(false);
            setShowAutofirmaModal(false);
          });
      }

    useEffect(checkAutofirma, []);

    return (
        <>
            <Modal
                title=""
                footer={null}
                open={showAutofirmaModal}
                closable={false}
            >
                {isAutofirma === 'optional' && <AutofirmaOptional loadingAutofirma={loadingAutofirma} setShowAutofirmaModal={setShowAutofirmaModal} startCorrectSignMethod={startCorrectSignMethod} signDocAutofirma={signDocAutofirma} setAutofirma={setAutofirma} />}
                {isAutofirma === 'mandatory' && <AutofirmaMandatory />}
            </Modal >
            <Modal
                title={t("general.error_modal")}
                footer={null}
                open={showModalErrorAutofirma}
                closable={true}
                onCancel={() => { setShowModalErrorAutofirma(false); setAutofirma(false) }}
            >
                <div className="modal-container modal-autofirma">
                    {t("sign.autofirma_error_description")}
                <a className="green" href='https://firmaelectronica.gob.es/Home/Descargas.html' target='_blank'>https://firmaelectronica.gob.es/Home/Descargas.html</a>
                </div>
                <div className="text-center">
                <button
                    type="button"
                    className="btn btn-primary btn-active single-button-width"
                    onClick={() => { setShowModalErrorAutofirma(false); setAutofirma(false) }}
                >
                    <span className="document-preview-text">{t("general.ok")}</span>
                    <CheckIcon />
                </button>
                </div>
            </Modal>
            <Modal
                title=""
                footer={null}
                open={showModalSigningAutofirma}
                closable={false}
            >
                <div className="modal-container modal-autofirma">
                    {t("sign.autofirma_signing_title")}
                </div>
            </Modal>
            <ShowErrorModal openShowErrorModal={error} setOpenShowErrorModal={setError} errorMessage={error} isBatchMultisignature={isBatchMultisignature} />
        </>
    );
};