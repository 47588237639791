import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmailSignatureService from '../../services/EmailSignatureService';
import OauthApiService from '../../services/OauthService';
import SignatureService from '../../services/SignatureService';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../components/Loader/Loader';
import ThemeService from '../../services/ThemeService';
import { AuthContext } from '../../contexts/AuthContext';

export const CriiptoCallback = () => {
  const [error, setError] = useState(null);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setIsAuthenticated } = useContext(AuthContext);

  const getStateParams = () => {
    const windowUrl = window.location.search;
    const querystring = new URLSearchParams(windowUrl);

    let state = querystring.get("state");

    if (!state) {
      setError(t("general.error") + ". URL: " + window.location.href);
      return;
    }

    const decodedState = atob(state);

    const paramsArray = decodedState.split(',');

    return paramsArray;
  }

  useEffect(() => {
    const windowUrl = window.location.search;
    const querystring = new URLSearchParams(windowUrl);
    const code = querystring.get("code");

    if (!code) {
      setError(t("general.error") + ". URL: " + window.location.href);
      return;
    }

    const [emailId] = getStateParams();

    OauthApiService.getToken((emailId))
    .then(() => {
        ThemeService.setTheme(emailId);
        SignatureService.pendingSignatures(emailId)
        .then(async (signaturesResponse) => {
            const document = signaturesResponse.data.data[0];

            const body = {
                code
            };
            

            EmailSignatureService.sendCriiptoCode(emailId, body)
                .then(() => {
                  // Allow navigation to next view
                  setIsAuthenticated(true);
                    let link = "/sign";
                    if (document.SignerDTO.AttachIdDocImages) link = "/attach-images";

                    navigate(link, {
                        state: {
                            documents: document,
                            emailId,
                            otpCode: ''
                        }
                  });
                })
        })
    })
      .catch((e) => {
        const statusCode = e?.response?.status;
        if (statusCode === 400) {
          setError(t("general.error"));
          return;
        }
        if (statusCode === 401) {
          setError(t("general.error"));
          return;
        }
        setError(t("general.error"));
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>
    {error && error}
    {!error && <Loader />}
  </>;
}