import { Modal } from "antd";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LOADING_URL } from "../../../constants/GeneralConstants";
import { CheckIcon } from "../../SvgComponents/CheckIcon/CheckIcon";

export const ShowErrorModal = ({ openShowErrorModal, setOpenShowErrorModal, errorMessage, isBatchMultisignature }) => {
    const { t } = useTranslation();
    const title = isBatchMultisignature ? t('document_warning.title') : t('general.error_modal');

    const handleErrorModal = () => {
        setOpenShowErrorModal(false);
        if (isBatchMultisignature) {
            const environment = localStorage.getItem("ENV");
            const baseUrl = environment === 'local' ? 'http://localhost:3000' : 'https://app-vidsigner-vidremote-v2-dev.azurewebsites.net';
            const url = localStorage.getItem(LOADING_URL);
            const link = baseUrl + "/loading" + url;
            window.top.location.href = link;
        }
    }

    return (
        <Modal
            title={title}
            footer={null}
            open={openShowErrorModal}
            closable={true}
            onCancel={() => setOpenShowErrorModal(false)}
        >
            <div className="modal-container">
                <p className="nu-input-label">{errorMessage}</p>
                <Col className="action-buttons mobile-button">
                    <Row>
                        <Col md={3} xs={3}></Col>
                        <Col md={6} xs={6}>
                            <button
                                type="button"
                                className="btn btn-primary btn-active"
                                onClick={() => handleErrorModal()}
                            >
                                <span className="cancel-text">{t("general.ok")}</span>
                                <CheckIcon />
                            </button>
                        </Col>
                        <Col md={3} xs={3}></Col>
                    </Row>
                </Col>
            </div>
        </Modal>
    );
};