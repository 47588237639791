import { useState } from "react";
import "./OneShotCheckbox.css";
import { useTranslation } from "react-i18next";
import { Col, Form } from 'react-bootstrap';
import OneShotService from "../../services/OneShotService";
import whiteLoaderGif from '../../assets/images/white_loader.gif';
import Helper from "../../util/helper";
import { ShowErrorModal } from "../Modals/ShowErrorModal/ShowErrorModal";


export const OneShotCheckbox = ({ oneShotRef, oneShot, setInputOneShot, signerGuid, requestId }) => {
  const { t } = useTranslation();
  const [oneShotInput, setOneShotInput] = useState(false);
  const [contractAcceptanceLoading, setContractAcceptanceLoading] = useState(false);
  const [openShowErrorModal, setOpenShowErrorModal] = useState(false);
  const errorMessage = t('sign.one_shot.get_contract_error_message');

  const handleCheckboxChange = (e) => {
    const value = !oneShotInput;
    setInputOneShot(value);
    setOneShotInput(value);
  };


  const getContractPDF = () => {
    setContractAcceptanceLoading(true);
    OneShotService.getContract(signerGuid, requestId)
      .then(response => {
        setContractAcceptanceLoading(false);
        if (response) {
          const base64PDF = response.data.data.DocumentContent;
          var file = new Blob([Helper.base64ToArrayBuffer(base64PDF)], { type: 'application/pdf' });
          var fileURL = window.URL.createObjectURL(file);
          window.open(fileURL);
          return;
        }

        setOpenShowErrorModal(true);

      },
        error => {
          setContractAcceptanceLoading(false);
          setOpenShowErrorModal(true);
        });
  };

  return (
    <>
      {oneShot &&
        <div className="rgpd-text" ref={oneShotRef}>
          <Col md={12} lg={12} sm={12}>
            <Form.Check type="checkbox">
              <Form.Check.Input className="rgpd-input" type="checkbox" value={oneShotInput} onChange={handleCheckboxChange} />
              <Form.Check.Label className="rgpd-label"> {t('sign.one_shot.conditions')}
                {!contractAcceptanceLoading &&
                  <span className="more-info" onClick={() => getContractPDF()}> {t('sign.one_shot.see_conditions')}</span>
                }
                {contractAcceptanceLoading &&
                  <img className="one-shot-loader" src={whiteLoaderGif} alt="LoadingGif" />
                }
              </Form.Check.Label>
            </Form.Check>
          </Col>
        </div>
      }
      <ShowErrorModal openShowErrorModal={openShowErrorModal} setOpenShowErrorModal={setOpenShowErrorModal} errorMessage={errorMessage} />
    </>
  );
};
