import Helper from "../util/helper";
import { axiosInstance } from "./RemoteService";

const SignatureService = {};

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001/api';

const baseApiUrl = backendUrl;

SignatureService.pendingSignatures = (emailId) => {
  const body = {
    "Terms": {
      "EmailId": emailId
    }
  };

  return axiosInstance.post(`${baseApiUrl}/pendingsignatures/searches`, body);
}

SignatureService.signatureFormResponse = (signerGuid, form, formResponse, isAdvancedForm) => {
  const formResponseDTO = Helper.responsesToForm(form, formResponse, isAdvancedForm);
  return axiosInstance.put(`${baseApiUrl}/signatures/${signerGuid}/formresponse`, formResponseDTO);
}
export default SignatureService;