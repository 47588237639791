import { axiosInstance } from "./RemoteService";

const SupportTicketService = {};

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001/api';

const baseApiUrl = backendUrl;

SupportTicketService.sendSupportTicket = (body) => {

  return axiosInstance.post(`${baseApiUrl}/supporttickets`, body);
}

export default SupportTicketService;