import React, { createContext, useState, useEffect } from 'react';
export const LangContext = createContext();

export const LangProvider = ({ children }) => {
  const [lang, setLang] = useState('');

  useEffect(() => {
    const savedLang = localStorage.getItem('i18nextLng');
    setLang(savedLang);

    const dir = savedLang === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.setAttribute('dir', dir);
  }, []);

  return (
    <LangContext.Provider value={{ lang, setLang }}>
      {children}
    </LangContext.Provider>
  );
};