import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmailSignatureService from '../../services/EmailSignatureService';
import OauthApiService from '../../services/OauthService';
import SignatureService from '../../services/SignatureService';
import { getEvidenceData } from '../../util/getEvidenceData';
import { useNavigate } from 'react-router-dom';
import ThemeService from '../../services/ThemeService';
import { AuthContext } from '../../contexts/AuthContext';
import { BatchContext } from '../../contexts/BatchContext';
import { IS_FROM_BATCH_LIST, IS_SSI_AUTH_BATCH } from '../../constants/GeneralConstants';

export const Auth = () => {
  const [error, setError] = useState(null);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setIsAuthenticated } = useContext(AuthContext);
  const { addDocumentsToBatch } = useContext(BatchContext);

  const getStateParams = () => {
    const windowUrl = window.location.search;
    const querystring = new URLSearchParams(windowUrl);

    let state = querystring.get("state");

    if (!state) {
      setError(t("general.error") + ". URL: " + window.location.href);
      return;
    }

    const decodedState = atob(state);

    const paramsArray = decodedState.split(',');

    return paramsArray;
  }

  useEffect(() => {
    const windowUrl = window.location.search;
    const querystring = new URLSearchParams(windowUrl);
    const code = querystring.get("code");

    if (!code) {
      setError(t("general.error") + ". URL: " + window.location.href);
      return;
    }

    const [targetName, emailId, signerGuid] = getStateParams();

    OauthApiService.getToken((emailId))
    .then(() => {
        ThemeService.setTheme(emailId);
        SignatureService.pendingSignatures(emailId)
        .then(async (signaturesResponse) => {
            const document = signaturesResponse.data.data[0];
            const documentLength = signaturesResponse.data.data.length;
            if (documentLength > 1) {
              addDocumentsToBatch(signaturesResponse.data.data);
              localStorage.setItem(IS_FROM_BATCH_LIST, "true");
              localStorage.setItem(IS_SSI_AUTH_BATCH, "true");
            }

            const evidenceData = await getEvidenceData();

            const body = {
                ...evidenceData,
                Code: code,
                SignerGuid: signerGuid
            };

            EmailSignatureService.sendSSICode(targetName, body)
                .then(() => {
                   // Allow navigation to next view
                   setIsAuthenticated(true);

                  let link = "/sign";
                  if (document.SignerDTO.AttachIdDocImages) link = "/attach-images";

                  navigate(link, {
                      state: {
                        documents: document,
                        emailId,
                        otpCode: '',
                        ssiBatch: documentLength > 1,
                        signerGuid: signerGuid,
                      }
                  });
                })

        })
    })
      .catch((e) => {
        navigate("/document-error");
        const statusCode = e?.response?.status;
        if (statusCode === 400) {
          setError(t("general.error"));
          return;
        }
        if (statusCode === 401) {
          setError(t("general.error"));
          return;
        }
        setError(t("general.error"));
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>
    {error && error}
  </>;
}