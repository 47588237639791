import { useContext } from "react";
import { FormContext } from "../../contexts/FormContext";
import Helper from "../../util/helper";
import './FormTitle.css';

export const FormTitle = ({ isAdvancedForm, title }) => {
  const titleValue = isAdvancedForm ? title.text : title;
 
  const { isHTML, getScreenDimensions } = useContext(FormContext);

  const getTitleStyles = () => {
    const { fontFamily } = getScreenDimensions();

    const { posx, posy, sizex } = title.position;
    return Helper.getElementPositionStylesNew(posx, posy, sizex, fontFamily);
  }

  return isHTML ? (
    <div
      style={isAdvancedForm ? getTitleStyles() : {}}
      className={`${isAdvancedForm ? 'advanced-form' : ''} form-section-title`}
      dangerouslySetInnerHTML={Helper.mapInnerHtmlData(titleValue)}
    />
  ) : (
    <div style={isAdvancedForm ? getTitleStyles() : {}} className={`${isAdvancedForm ? 'advanced-form' : ''} form-section-title`}>
      {titleValue}
    </div>
  );
};
