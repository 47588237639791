import React, { useContext, useEffect, useState } from 'react';
import "./Loading.css";
import i18n from '../../config/i18n';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import OauthApiService from './../../services/OauthService';
import SignatureService from '../../services/SignatureService';
import PersonalCodeService from '../../services/PersonalCodeService';
import documentLoadingGif from '../../assets/images/logo_vidsigner_green.gif';
import grayLoaderGif from '../../assets/images/gray_loader.gif';
import { EMAIL_CLICKED, FIRST_LOAD, IS_CUSTOM, IS_FROM_BATCH_LIST, IS_SSI_AUTH_BATCH, LOADING_URL, SHOW_ONBOARDING_PAGE } from '../../constants/GeneralConstants';
import { useNavigate, useLocation } from "react-router-dom";
import { BatchContext } from '../../contexts/BatchContext';
import Helper from '../../util/helper';
import { PersonalCodeModal } from '../../components/Modals/PersonalCodeModal/PersonalCodeModal';
import ThemeService from '../../services/ThemeService';
import { URLReturnContext } from '../../contexts/URLReturnContext';
import EmailSignatureService from '../../services/EmailSignatureService';
import { getEvidenceData } from '../../util/getEvidenceData';
import { LangContext } from '../../contexts/LangContext';

export const Loading = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [personalCodeModal, setPersonalCodeModal] = useState(false);
  const [error, setError] = useState(false);
  const showOnboardingPage = localStorage.getItem(SHOW_ONBOARDING_PAGE);
  const [emailId, setEmailId] = useState("");
  const [isCustom, setIsCustom] = useState(false);

  const { addDocumentsToBatch } = useContext(BatchContext);
  const { setLang } = useContext(LangContext);
  const { decodeURL } = useContext(URLReturnContext);
  const isLocal = window.location.hostname === 'localhost';
  const environment = isLocal ? 'local' : 'server';
  localStorage.setItem("ENV", environment);
  const isFromDocument = location?.state?.isFromDocument;

  // On Init
  useEffect(() => {
    getParams();
    localStorage.setItem(FIRST_LOAD, "true");
    const isCustom = localStorage.getItem(IS_CUSTOM) === "true";
    localStorage.removeItem(IS_SSI_AUTH_BATCH);
    setIsCustom(isCustom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getParams = () => {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const emailId = url.searchParams.get("emailid");
    localStorage.setItem(LOADING_URL, url.search);
    if (emailId) {
      setEmailId(emailId);
      const lang = url.searchParams.get("lang");
      setLang(lang);
      i18n.changeLanguage(lang);
      const isOpenedFromDocumentsList = url.searchParams.get("isOpenedFromDocumentsList");
      Helper.setIsOpenedFromDocumentList(isOpenedFromDocumentsList);
      if (typeof isOpenedFromDocumentsList === "undefined") {
        Helper.setIsOpenedFromDocumentList(false);
      }

      const urlReturn = url.searchParams.get('url_return');
      if (urlReturn) decodeURL(urlReturn);

      getToken(emailId);
      return;
    }
    setError(true);
  }

  const getToken = async (emailId) => {
    await OauthApiService.getToken(emailId)
      .then(response => {
        getAccessWithPersonalCode(emailId);
        ThemeService.setTheme(emailId);
      })
      .catch(e => {
        navigate("/document-error");
        console.error(e);
        setError(true);
      });
  }

  const pendingSignature = async (emailId) => {
    await SignatureService.pendingSignatures(emailId)
      .then(response => {
        const documentsResponse = response.data.data;

        if (documentsResponse.length === 0) {
          navigate("/document-error");
          return;
        }

        // Single document
        if (documentsResponse.length === 1) {
          localStorage.setItem(IS_FROM_BATCH_LIST, "false");
          const document = documentsResponse[0];
          let link = "/document";
          if (document.SignerDTO.Form && document.SignerDTO.Form.FormDisplay.toLowerCase() === "beforepreview") link = "/questions";
          if (document.SignerDTO.Form && document.SignerDTO.Form.FormDisplay.toLowerCase() === "onpreview") link = "/document-questions";

          if ((showOnboardingPage === "true" || showOnboardingPage === null) && !isFromDocument) {
            localStorage.setItem(SHOW_ONBOARDING_PAGE, "true");
            link = "/home";
          }

          if (document.SignerDTO.SignerData) {
            link = "/signer-data"
          }

          navigate(link, {
            state: {
              documents: document,
              emailId: emailId
            }
          });
          return;
        }

        // Multiple documents
        let link = "/documents";
        localStorage.setItem(IS_FROM_BATCH_LIST, "true");
        if ((showOnboardingPage === "true" || showOnboardingPage === null) && !isFromDocument) {
          localStorage.setItem(SHOW_ONBOARDING_PAGE, "true");
          link = "/home";
        }
        addDocumentsToBatch(documentsResponse);

        navigate(link, {
          state: {
            documents: documentsResponse,
            emailId
          }
        });

      })
      .catch(e => {
        console.error(e);
        setError(true);
      });
  }

  const getAccessWithPersonalCode = async (emailId) => {
    const response = await PersonalCodeService.getAccessWithPersonalCode(emailId);
    if (response.data.data.AccessWithPersonalCode) {
      setPersonalCodeModal(true);
      return;
    } else {
      pendingSignature(emailId);
      sendEvidence(emailId);
    }
  }

  const sendEvidence = async (emailId) => {
    const evidenceData = await getEvidenceData();

    const body = {
      EvidenceIP: evidenceData.IpAddress,
      EvidenceLocation: evidenceData.Location,
      From: evidenceData.From,
      EvidenceType: EMAIL_CLICKED
    };

    EmailSignatureService.sendEvidence(emailId, body)
      .then(() => {
      })
      .catch((e) => {
      });
  }

  return (
    <>
      <Container>
        <Row className="container-row">
          <Col md={2}></Col>
          <Col md={8} xs={12} className="justify-content-center container-box">
            <Row>
              <Col md={12}>
                <img src={isCustom ? grayLoaderGif : documentLoadingGif} alt="LoadingGif" />
              </Col>
              <Col md={12}>
                <br></br>
                {!error &&
                  <h4 className="title-loading-documents">{t('document_detail.loading2')}</h4>
                }
                {error &&
                  <h4 className="title-loading-documents invalid-url">Invalid URL</h4>
                }
              </Col>
            </Row>
          </Col>
          <Col md={2}></Col>
        </Row>
      </Container>
      <PersonalCodeModal personalCodeModal={personalCodeModal} setPersonalCodeModal={setPersonalCodeModal} emailId={emailId} pendingSignature={pendingSignature}></PersonalCodeModal>
    </>
  );
}