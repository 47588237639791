import { axiosInstance } from "./RemoteService";

const PersonalCodeService = {};

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001/api';

const baseApiUrl = backendUrl;

PersonalCodeService.getAccessWithPersonalCode = (emailId) => {
  return axiosInstance.get(`${baseApiUrl}/emailsignatures/${emailId}/configuration`);
}

PersonalCodeService.putValidatePersonalCodeRequest = (emailId, body) => {
  return axiosInstance.put(`${baseApiUrl}/emailsignatures/${emailId}/personalcode`, body);
}

export default PersonalCodeService;