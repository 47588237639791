import { axiosInstance } from "./RemoteService";

const LocalSignatureService = {};

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001/api';

const baseApiUrl = backendUrl;

LocalSignatureService.sendCertificateCalculatePreSignature = (signerGuid, body) => {

  return axiosInstance.post(`${baseApiUrl}/localsignature/${signerGuid}/certificate`, body);
}

LocalSignatureService.sendSignatureResult = (signerGuid, body) => {

  return axiosInstance.post(`${baseApiUrl}/localsignature/${signerGuid}`, body);
}

LocalSignatureService.getSignatureHash = (signerGuid) => {

  return axiosInstance.get(`${baseApiUrl}/presignature/${signerGuid}`);
}

export default LocalSignatureService;