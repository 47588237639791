import axios from 'axios';

const axiosInstance = axios.create({
  withCredentials: true,
  allow_headers: ['Content-Type', 'Authorization', 'locale'],
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 403 && error.response.data?.includes('The Personal Code has expired')) {
        window.location.href = '/expired-personal-code';
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

export { axiosInstance };