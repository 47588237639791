import { axiosInstance } from "./RemoteService";

const RejectedSignatureService = {};

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001/api';

const baseApiUrl = backendUrl;

RejectedSignatureService.rejectDocument = (signerGuid, body) => {
  return axiosInstance.post(`${baseApiUrl}/rejectedsignature/${signerGuid}`, body);
}

export default RejectedSignatureService;