import { axiosInstance } from "./RemoteService";

const DownloadDocumentService = {};

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001/api';

const baseApiUrl = backendUrl;

DownloadDocumentService.downloadDocument = (docGuid) => {

  return axiosInstance.get(`${baseApiUrl}/documents/${docGuid}`);
}

export default DownloadDocumentService;