import { axiosInstance } from "./RemoteService";

const SignerDataService = {};

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001/api';

const baseApiUrl = backendUrl;

SignerDataService.sendSignerData = (signerGuid, body) => {
  return axiosInstance.put(`${baseApiUrl}/signers/${signerGuid}/signerdata`, body);
}

export default SignerDataService;