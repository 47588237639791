import { axiosInstance } from "./RemoteService";

const DocumentService = {};

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001/api';

const baseApiUrl = backendUrl;

DocumentService.documentRendered = (docGuid, pageNumber) => {

  return axiosInstance.get(`${baseApiUrl}/documentrendered/${docGuid}/pages/${pageNumber}`);
}

DocumentService.deleteDocumentRendered = (docGuid) => {

  return axiosInstance.delete(`${baseApiUrl}/documentrendered/${docGuid}`);
}

export default DocumentService;